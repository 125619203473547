import React from "react";
import Layout from "../../../Components/Layout";
import { Link } from "react-router-dom";

const Profile = () => {

    return (
        <>
            <Layout>
                <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/settings" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Profile</h3>
                        </div>
                    </div>
                </header>

                <section className="pt-20">
                    <div className="container">
                        <ul className="flex flex-col gap-7 bg-white rounded-lg px-4 py-6">
                            {/* <li className="border-b border-custom-border pb-3">
                                <p className="text-xs font-normal text-custom-text-color">Name</p>
                                <h5 className="mt-2 text-sm text-custom-text-color2">Kenneth Tham</h5>
                            </li> */}
                            <li className="border-b border-custom-border pb-3">
                                <p className="text-xs font-normal text-custom-text-color">Join Date</p>
                                <h5 className="mt-2 text-sm text-custom-text-color2">
                                    12 March 2023 12.23 pm
                                </h5>
                            </li>
                            <li className="border-b border-custom-border pb-3">
                                <p className="text-xs font-normal text-custom-text-color">Email</p>
                                <h5 className="mt-2 text-sm text-custom-text-color">demo@gmail.com</h5>
                            </li>
                            <li className="border-b border-custom-border pb-3">
                                <p className="text-xs font-normal text-custom-text-color">
                                    Mobile Number
                                </p>
                                <h5 className="mt-2 text-sm text-custom-text-color2">016524584254</h5>
                            </li>
                        </ul>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default Profile;