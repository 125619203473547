    import React, { useEffect, useState } from "react";
    import { Link, useNavigate } from "react-router-dom";
    import Layout from "../../../Components/Layout";
    import { useDispatch, useSelector } from "react-redux";
    import { RootState } from "../../../Redux/store";
    import { fetchEarnigReport } from "../../../Redux/thunks/earningReportThunk";
    import { useFormik } from "formik";
    import { fetchSingleEarnigReport } from "../../../Redux/thunks/singleEraningThunk";

    const SponseredNetwork = () => {
        const { earningData } = useSelector((state: RootState) => state.earningReport);
        const dispatch = useDispatch<any>();
        const initialReport = { period: "" };
        const [open ,setOpen] = useState(false);

        useEffect(() => {
            dispatch(fetchEarnigReport({}));
        }, [dispatch]);

        const formSubmit = (values: any) => {
            const params = values.period ? { period: values.period } : {};
            dispatch(fetchEarnigReport(params));
        };

        const formik = useFormik({
            initialValues: initialReport,
            onSubmit: formSubmit,
        });

        const formattedData = earningData ? [
            { type: "referral_bonus", balance: earningData.referral_bonus.balance || "0.00" },
            { type: "binary_bonus", balance: earningData.binary_bonus.balance || "0.00" },
            { type: "leadership_income", balance: earningData.leadership_income.balance || "0.00" },
            { type: "repeat_sale_income", balance: earningData.repeat_sale_income.balance || "0.00" },
            { type: "roll_up_bonus", balance: earningData.roll_up_bonus.balance || "0.00" },
        ] : [];
     
        const handleOpen = (bonus: string, period: string) => {
            dispatch(fetchSingleEarnigReport({ bonus, period , "per_page": 10,
                "page" : 1  }));
            setOpen(!open);
        };
        
        const { singleearningData } = useSelector((state: RootState) => state.singleEarningData);
      
        useEffect(() => {
            dispatch(fetchSingleEarnigReport({}));
        }, [dispatch]);

        return (
            <Layout>
               
               {
                open ? (
                    <>
                    <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <div onClick={()=>setOpen(false)} className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </div>
                            <h3 className="text-lg font-medium">View Commissions</h3>
                        </div>
                    </div>
                </header>
                <section className="pt-20">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr >
                                        <th className="px-6 py-5" >Member Id</th>
                                        <th className="px-6 py-5" >Currency</th>
                                        <th className="px-6 py-5" >Period</th>
                                        <th className="px-6 py-5" >Trans Source Type</th>
                                        <th className="px-6 py-5" >Trans Id</th>
                                        <th className="px-6 py-5" >Trans No</th>
                                        <th className="px-6 py-5" >Description</th>
                                        <th className="px-6 py-5" >Debit</th>
                                        <th className="px-6 py-5" >Credit</th>
                                        <th className="px-6 py-5" >Balance</th>
                                        <th className="px-6 py-5" >Insert Time</th>
                                        <th className="px-6 py-5" >Insert By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {singleearningData ? (singleearningData && 
                                            singleearningData.map((item: any, index: number) => (
                                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#efeff1]"}>
                                                    <td className="px-6 py-4">{item.member_id}</td>
                                                    <td className="px-6 py-4">{item.currency}</td>
                                                    <td className="px-6 py-4">{item.period}</td>
                                                    <td className="px-6 py-4">{item.trans_source_type}</td>
                                                    <td className="px-6 py-4">{item.trans_id}</td>
                                                    <td className="px-6 py-4">{item.trans_no}</td>
                                                    <td className="px-6 py-4">{item.description}</td>
                                                    <td className="px-6 py-4">{item.debit}</td>
                                                    <td className="px-6 py-4">{item.credit}</td>
                                                    <td className="px-6 py-4">{item.balance}</td>
                                                    <td className="px-6 py-4">{item.insert_time}</td>
                                                    <td className="px-6 py-4">{item.insert_by}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={12} className="px-6 py-2 text-center">No data available</td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                </section>
                    </>
                ):
                (
                    <>
                     <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/reports" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Member Commissions</h3>
                        </div>
                    </div>
                </header>
                    <section className="pt-20">
                    <div className="container">
                        <div className="bg-white py-10 px-3">
                            <form onSubmit={formik.handleSubmit}>
                                <label className="text-xs text-[#a8a1a7]">Select Period</label>
                                <select
                                    {...formik.getFieldProps('period')}
                                    className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs border rounded-md px-2 py-1"
                                >
                                    <option value="">Select</option>
                                    <option value="2024-01">Jan 2024</option>
                                    <option value="2024-02">Feb 2024</option>
                                    <option value="2024-03">Mar 2024</option>
                                    <option value="2024-04">Apr 2024</option>
                                    <option value="2024-05">May 2024</option>
                                    <option value="2024-06">Jun 2024</option>
                                    <option value="2024-07">Jul 2024</option>
                                    <option value="2024-08">Aug 2024</option>
                                    <option value="2024-09">Sep 2024</option>
                                    <option value="2024-10">Oct 2024</option>
                                    <option value="2024-11">Nov 2024</option>
                                    <option value="2024-12">Des 2024</option>
                                </select>
                                <div className="mt-4">
                                    <button type="submit" className="py-3 w-full bg-[#1dcc71] text-xs text-white rounded-md">Search</button>
                                </div>
                            </form>

                            <div className="pt-10">
                                <h3 className="text-sm sm:text-lg">Commission List</h3>
                            </div>

                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <tbody>
                                        {formattedData.length > 0 ? (
                                            formattedData.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#efeff1]"}>
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {item.type}
                                                    </th>
                                                    <td className="px-6 py-4">
                                                        ${item.balance || 0}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <button className="px-4 bg-[#1dcc71] text-xs text-white rounded-md py-2" onClick={() => handleOpen(item.type, formik.values.period)}>View</button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={3} className="px-6 py-2 text-center">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </section>
                    </>
                )
               }
            </Layout>
        );
    };

    export default SponseredNetwork;
