import React from 'react';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRouter, CheckAuthRouter } from "./PrivateRouter";

import Login from "../Pages/Guest/Login";

import Dashboard from "../Pages/Auth/Dashboard";
import Setting from "../Pages/Auth/Setting";
import Profile from "../Pages/Auth/Settings/Profile";
import PrimaryPassword from "../Pages/Auth/Settings/PrimaryPassword";
import SecondaryPassword from "../Pages/Auth/Settings/SecondaryPassword";
import Share from "../Pages/Auth/Settings/Share";
import Reports from '../Pages/Reports/Reports';
import MyTeam from '../Pages/MyTeam/MyTeam';
import SponseredTree from '../Pages/Reports/ReportsData/SponseredTree';
import SponseredNetwork from '../Pages/Reports/ReportsData/SponseredNetwork';
import SposoredNetwork from '../Pages/MyTeam/SposoredNetwork';
import SponsoredTree from '../Pages/MyTeam/SponsredTree';
import AddMemberForm from '../Pages/MyTeam/AddMemberForm';
import AddBankPage from '../Pages/Auth/Settings/AddBankPage';
import AddBankForm from '../Pages/Auth/Settings/AddBankForm';
import Withdrawal from '../Pages/Auth/Settings/Withdrawal';
import SecurityOtpConfirm from '../Pages/Auth/Settings/SecurityOtpConfirm';
import ResetLogin from '../Pages/Guest/ResetLogin';
import ResetChangePass from '../Pages/Guest/ResetChangePass';

const BrowserRoute = () => {

    return (
        <Router>
            <Routes>
                {/* Guest Routers */}
                <Route element={<CheckAuthRouter/>}>
                    <Route path="/" element={<Login/>}/>
                    <Route path="/resetpassword" element={<ResetLogin/>}/>
                    <Route path="/changepassword" element={<ResetChangePass/>}/>
                </Route>

                {/* Private Routers */}
                <Route element={<PrivateRouter />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/settings" element={<Setting />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/myteam" element={<MyTeam />} />
                    <Route path="/memberleger" element={<SponseredTree />} />
                    <Route path="/sponsoredtnetwork" element={<SponseredNetwork />} />
                    <Route path="/sposorednetowork" element={<SposoredNetwork />} />
                    <Route path="/plcementtree" element={<SponsoredTree />} />
                    <Route path="/addmember" element={<AddMemberForm />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/primary_password" element={<PrimaryPassword />} />
                    <Route path="/security_password" element={<SecondaryPassword />} />
                    <Route path="/share" element={<Share />} />
                    <Route path="/securityotpconfirmation" element={<SecurityOtpConfirm />} />
                    <Route path="/withdrawal" element={<Withdrawal />} />
                    <Route path="/addbankpage" element={<AddBankPage />} />
                    <Route path="/addbankform" element={<AddBankForm />} />
                </Route>

                {/* Error 404 */}
                {/*<Route path="*" element={<E404 />} />*/}
            </Routes>
        </Router>
    );
}

export default BrowserRoute;