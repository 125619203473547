// src/Redux/thunks/balanceThunks.ts

import { Dispatch } from 'redux';
import { AxiosBankListDetail } from '../../Utilities/axios';
import { fetchBankListFailure, fetchBankListRequest, fetchBankListSuccess } from '../actions/bankListAction';

export const fetchBankList = (id : any) => {
    
    return async (dispatch: Dispatch) => {
        dispatch(fetchBankListRequest());
        try {
            const response = await AxiosBankListDetail.get("");
            dispatch(fetchBankListSuccess(response.data.data));
        } catch (error: any) {
            dispatch(fetchBankListFailure(error.message || 'Failed to fetch banklist data'));
        }
    };
};
