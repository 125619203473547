import React, { useEffect } from 'react'
import Layout from '../../Components/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { fetchSponsoredTree } from '../../Redux/thunks/sponsoredTree';
import { LuUser } from "react-icons/lu";
import { FaRegPlusSquare } from "react-icons/fa";

// Updated interfaces to reflect the API response structure
interface TreeDataItem {
    accu_left_node: string;
    accu_right_node: string;
    addition_left_node: string;
    addition_right_node: string;
    deduction_left_node: string;
    deduction_right_node: string;
    bleft_node: string;
    bright_node: string;
    f_name: string;
    l_name: string;
    username: string;
    userid: string;
    rank: string;
    package: string;
    member_id: number;
    side: string,
    upline_id: string
}

interface SponsoredTreeItem {
    userid: string;
    rank: string;
    package: string;
    data: TreeDataItem;
    type: string;
    col:string,
     upline_id: number
}
  
  interface LevelGridProps {
    levelData: SponsoredTreeItem[];
    gridClass: string;
    handlePersonCall: (userid: string) => void; 
  }

  const LevelGrid: React.FC<LevelGridProps> = ({ levelData =[], gridClass ,handlePersonCall}) => {
    const navigate = useNavigate()
    const handleNavigate = (col : string,  upline_id:  number) => {
        navigate('/addmember', { state: { col, upline_id },});
      }

    return (
        <div className={gridClass}>
          {levelData.length > 0 ? (
            levelData.map((item, index) => (
              <React.Fragment key={index}>
                {item.type === 'person' ? (
                  <div  className={`my-7 cursor-pointer text-cenetr  ${
                    item.col === "L" ? "" : item.col === "R" ? "" : ""
                  }`} onClick={() =>handlePersonCall(item.userid)}>
                    <LuUser className='mb-2 inline' />
                    <h3 className='text-[10px] text-black'>
                      {item.userid} (
                      {item.data ? (
                        <>
                          {item.data.rank}
                        </>
                      ) : ""}
                      )
                    </h3>
                    <h3 className='text-[10px] text-black'>({item.data.package})</h3>
                    <h3 className='text-[10px] text-black'>{item.userid}</h3>
                    <h3 className='text-[10px] text-black'>
                      L : {item.data.accu_left_node || 0} | R : {item.data.accu_right_node || 0}
                    </h3>
                  </div>
                ) : item.type === 'blank' ? (
                 <div className='mx-auto'>
                      <div onClick={() => handleNavigate(item.col , item.upline_id)}>
                    <FaRegPlusSquare className='cursor-pointer' />
                    </div>
                 </div>
                  ) : (
                 ""
                )}
              </React.Fragment>
            ))
          ) :  (
          ""
          )}
        </div>
      );
    };
    
function SponsoredTree() {
    const dispatch = useDispatch<any>();
    const { sponsoredTreeData } = useSelector((state: RootState) => state.sponsoreTree);

    useEffect(() => {
        dispatch(fetchSponsoredTree({ userid: '' }));
    }, [dispatch]);

    const handlePersonCall = (userid : string)=>{
        dispatch(fetchSponsoredTree({ userid: userid }));  
    }
    
    return (
        <>
            <Layout>
                <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/myteam" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Placement Tree</h3>
                        </div>
                    </div>
                </header>
                <section className="pt-20 pb-24">
                    <div className="container">
                        <div className="p-[20px] bg-white overflow-x-auto">
                        {/* <IoReturnUpBackOutline className='text-3xl' /> */}
                                <>
                                <div className="min-w-[1700px]">
                    <LevelGrid levelData={sponsoredTreeData.level1} gridClass="grid grid-cols-1 text-center" handlePersonCall={handlePersonCall} />
                    <LevelGrid levelData={sponsoredTreeData.level2} gridClass="grid grid-cols-2 text-center" handlePersonCall={handlePersonCall}  />
                    <LevelGrid levelData={sponsoredTreeData.level3} gridClass="grid grid-cols-4 text-center"  handlePersonCall={handlePersonCall} />
                    <LevelGrid levelData={sponsoredTreeData.level4} gridClass="grid grid-cols-8 text-center"  handlePersonCall={handlePersonCall} />
                    <LevelGrid levelData={sponsoredTreeData.level5} gridClass="custom-grid text-center" handlePersonCall={handlePersonCall}  />
                    </div>
                                </>
                      
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default SponsoredTree
