const ENV: string = 'Live';

const BASE_URL: string = ENV === 'development' ?  'http://127.0.0.1:8000' : 'https://staging.acp.sgcoders.net';
const API_URL: string = BASE_URL + '/api/';
const STORAGE_URL: string = BASE_URL + '/storage/';
const BALANCE_URLL :string = BASE_URL + '/api/getbalance'
const EWALATE_REPORT_URL : string = BASE_URL + '/api/e-wallet-report'
const EARNING_REPORT_URL : string = BASE_URL + '/api/earning-report'
const SINGALEARNING_REPORT_URL : string = BASE_URL + '/api/earning-report-detail'
const SPONSORED_TREE_URL : string = BASE_URL + '/api/placementtree'
const SPONSORED_NETWORK_URL : string = BASE_URL + '/api/sponsorednetwork'
const SUB_ACCOUNT_URL : string = BASE_URL + '/api/sub-accounts'
const SUB_ACCOUNT_LOGIN_URL : string = BASE_URL + '/api/sub-account-login'
const SUB_PRODUCTLIST_URL : string = BASE_URL + '/api/add-member-getdata'
const SUB_MOBILENUMBER_URL : string = BASE_URL + '/api/get-member-details'
const SUB_USERDETAIL_URL : string = BASE_URL + '/api/get-member-details'
const SUB_ADDMEMBER_URL : string = BASE_URL + '/api/add-member-post'
const SUB_ADDBANK_URL : string = BASE_URL + '/api/member-bank'
const SUB_GETBANKDETAIL_URL : string = BASE_URL + '/api/get-member-bank-list'
const SUB_BANKLIST_URL : string = BASE_URL + '/api/get-bank-list?'
const GET_LPBALANCE_URL : string = BASE_URL + '/api/get-lp-balance'
const WITHDRAWAL_REQUEST: string = BASE_URL + '/api/withdraw-request'
const CHANGE_LOGIN_PASSWORD: string = BASE_URL + '/api/change-login-password'
const CHANGE_SECURITY_PASSWORD: string = BASE_URL + '/api/change-security-password'
const SEND_LOGIN_OTP: string = BASE_URL + '/api/send-login-otp'
const VERIFY_LOGIN_OTP: string = BASE_URL + '/api/verify-login-otp'
const RESET_LOGIN_PASSSWORD: string = BASE_URL + '/api/reset-password'
const GET_SECURITY_EMAIL: string = BASE_URL + '/api/get-member-email'
const SEND_SECURITY_OTP : string = BASE_URL + '/api/send-security-otp'
const SECURITY_OTP : string = BASE_URL + '/api/verify-security-otp'
const NEW_SECURITY_PASSWORD : string = BASE_URL + '/api/reset-security-password'

export { BASE_URL, API_URL, STORAGE_URL, ENV ,BALANCE_URLL,EWALATE_REPORT_URL ,EARNING_REPORT_URL , SINGALEARNING_REPORT_URL ,SPONSORED_TREE_URL ,SPONSORED_NETWORK_URL ,SUB_ACCOUNT_URL ,SUB_ACCOUNT_LOGIN_URL,SUB_PRODUCTLIST_URL ,SUB_MOBILENUMBER_URL ,SUB_USERDETAIL_URL,SUB_ADDMEMBER_URL,SUB_ADDBANK_URL ,SUB_GETBANKDETAIL_URL ,SUB_BANKLIST_URL,GET_LPBALANCE_URL,WITHDRAWAL_REQUEST,CHANGE_LOGIN_PASSWORD,CHANGE_SECURITY_PASSWORD,SEND_LOGIN_OTP,VERIFY_LOGIN_OTP,RESET_LOGIN_PASSSWORD,GET_SECURITY_EMAIL,SEND_SECURITY_OTP,SECURITY_OTP,NEW_SECURITY_PASSWORD};
