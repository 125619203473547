import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import Layout from "../../../Components/Layout";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { fetchBankList } from "../../../Redux/thunks/bankListThunk";
import { fetchBankData } from "../../../Redux/thunks/AddBankThunk";
import { useLocation } from "react-router-dom";

  interface FormData {
    id: number;
    userid: string | null;
    bank_id: string;
    bank_acount_no: string;
  }

interface Product {
  id: number;
  bank_name: string;
  country_id: number;
}

const AddBankForm = () => {
  const { bankList } = useSelector((state: RootState) => state.bankListData); 
  const { addbankData } = useSelector((state: RootState) => state.addBank);     
  const ID = localStorage.getItem("contryid");
  const loginuser = localStorage.getItem("loginUser");
  const loginuserId = localStorage.getItem("loginUserId");

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(fetchBankList(ID));
  }, [dispatch]);

  const location = useLocation();
const bankData = location.state?.bankData || null;

const [formData, setFormData] = useState<FormData>({
  id: bankData?.id || 0,
  userid: loginuserId,
  bank_id: bankData?.bank_id || "",
  bank_acount_no: bankData?.bank_acount_no || "",
}); 
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();  
    try {
      await dispatch(fetchBankData(formData)); 
      await dispatch(fetchBankList(ID)); 
      navigate('/addbankpage'); 
    } catch (error) {
      console.error("Error updating bank details:", error);
    }
  };
  
  return (
    <>
      <Layout>
        <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
          <div className="container">
            <div className="relative">
              <Link to="/addbankpage" className="absolute left-0">
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m15 19-7-7 7-7"
                  />
                </svg>
              </Link>
              <h3 className="text-lg font-medium">Add BankDetails</h3>
            </div>
          </div>
        </header>
        <section className="py-20">
          <div className="container">
            <div className="p-[20px] bg-white rounded-md">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="text-[#1e293b] text-[14px]">Full Name</label>
                  <input
                    type="text"
                    name="userid"
                    placeholder="Full Name"
                    className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                    value={loginuser || ""} // Show loginuser from localStorage (Full Name)
                    disabled // Disable input so user can't change it
                  />
                </div>
                <div className="mb-3">
                  <label className="text-[#1e293b] text-[14px]">Select Bank</label>
                  <select
                    name="bank_id"
                    className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                    value={formData.bank_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Bank</option>
                    {bankList.map((product: Product) => (
                      <option key={product.id} value={product.id.toString()}>
                        {product.bank_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="text-[#1e293b] text-[14px]">Bank Account No.</label>
                  <input
                    type="text"
                    name="bank_acount_no"
                    placeholder="Bank Account No"
                    className="mt-2 w-full text-[14px] placeholder:text-[14px] border py-2 px-3 rounded-md"
                    value={formData.bank_acount_no}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <button type="submit" className="py-2 px-3 rounded-md bg-[#178285] text-white text-sm">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default AddBankForm;
