import React , { useEffect, useState }from "react";
import { Link } from "react-router-dom";
import Layout from "../../../Components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchEWalletReport } from "../../../Redux/thunks/ewelletReportThunk";
import { RootState } from "../../../Redux/store";
import { useFormik } from "formik";

const SponseredTree = () => {

    const { reportData} = useSelector((state: RootState) => state.ewalletReport);
    const dispatch = useDispatch<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const initialReport = {
        per_page: perPage,
        page: currentPage,
        currency: "",
        from_year: "",
        from_month: "",
        to_year: "",
        to_month: ""
    };

    useEffect(() => {
        dispatch(fetchEWalletReport(initialReport));
    }, [dispatch, currentPage, perPage]);

    const formSubmit = (values: any) => {
        dispatch(fetchEWalletReport(values));
    };

    const formik = useFormik({
        initialValues: initialReport,
        onSubmit: formSubmit,
    }) as any;

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const handlePerPageChange = (newPerPage: number) => {
        setPerPage(newPerPage);
        setCurrentPage(1);
    };

    const totalPages = Math.ceil(reportData / perPage);

    // useEffect(() => {
    //     if (reportData && reportData.length > 0) {
    //         const table = $('#example').DataTable({
    //             destroy: true, // Destroy any existing table before initializing a new one
    //         });

    //         return () => {
    //             table.destroy();
    //         };
    //     }
    // }, [reportData]);

    return (
        <>
            <Layout>
                <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/reports" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Member Ledger</h3>
                        </div>
                    </div>
                </header>

                <section className="pt-20  ">
                    <div className="container">
                    <div className="bg-white p-4 border rounded-md">
                        <form onSubmit={ formik.handleSubmit }>
                            <div className="mb-3">
                            <label className="text-xs text-[#a8a1a7]">currency</label>
                                <select {...formik.getFieldProps('currency')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                                    <option selected value="LP">LP</option>
                                    <option value="PP">PP</option>
                                </select>
                            </div>
                           <div className="mb-3">
                           <label className="text-xs text-[#a8a1a7]">From</label>
                            <div className="flex gap-20">
                            <select  {...formik.getFieldProps('from_year')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                                <option selected value='2024'>2024</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            <span>-</span>
                            <select {...formik.getFieldProps('from_month')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                                <option selected value='08'>Aug</option>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sep</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>
                            </div>
                           </div>
                           <div className="mb-3">
                           <label className="text-xs text-[#a8a1a7]">Until</label>
                            <div className="flex gap-20">
                            <select {...formik.getFieldProps('to_year')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                            <option selected value='2024'>2024</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                            </select>
                            <span>-</span>
                            <select  {...formik.getFieldProps('to_month')} className="text-[#5b5968] w-full border-b border-[#a8a1a7] text-xs">
                            <option selected value='08'>Aug</option>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sep</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>
                            </div>
                           </div>
                           <div className="mt-4">
                            <button type="submit" className="py-3 w-full bg-[#1dcc71] text-xs text-white rounded-md">View</button>
                           </div>
                        </form>
            <div className="relative overflow-x-auto mt-5 border rounded-md">
                <table  id="example" className="display table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Trans No
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Ledger Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Description
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Credit
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Debit
                            </th>
                            <th scope="col" className="px-6 py-3">
                            Balance
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        
                   {reportData && reportData.length > 0 ? (
                                            reportData.map((item: any, index: number) => (
                                                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#efeff1]"}>
                                                    <td className="px-6 py-2">{item.trans_no}</td>
                                                    <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {item.period}
                                                    </td>
                                                    <td className="px-6 py-2">{item.description}</td>
                                                    <td className="px-6 py-2">{item.credit}</td>
                                                    <td className="px-6 py-2">{item.debit}</td>
                                                    <td className="px-6 py-2">{item.balance}</td>
                                                </tr>
                                            ))
                    ) : (
                   <tr>
                   <td colSpan={6} className="px-6 py-2 text-center">No data available</td>
                   </tr>
                    )}
                    </tbody>
                </table>
            </div>
                <div className="flex justify-end py-3">
                <nav aria-label="Page navigation">
    <ul className="inline-flex -space-x-px text-sm">
        <li>
            <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
                Prev
            </button>
        </li>
        {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1}>
                <button
                    onClick={() => handlePageChange(index + 1)}
                    aria-current={currentPage === index + 1 ? "page" : undefined}
                    className={`flex items-center justify-center px-3 h-8 border border-gray-300 ${
                        currentPage === index + 1
                            ? 'text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                            : 'text-gray-500 bg-white hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                    }`}
                >
                    {index + 1}
                </button>
            </li>
        ))}
        <li>
            <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="flex items-center justify-center px-3 h-8 text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
                Next
            </button>
        </li>
    </ul>
</nav>

                </div>
                    </div>
                    </div>
                </section>
            </Layout>
        </>
    );
}

export default SponseredTree;