import React, { useEffect, useState } from 'react'
import Layout from '../../Components/Layout'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { fetchSponsredNetwork } from '../../Redux/thunks/sponsNewtworkThunk';

interface SponsoredNetworkItem {
    userid: string;
    rank_name: string;
    joining_date: string;
    sponsored: SponsoredNetworkItem[]; 
    id: number;
    sponsorid: number;
}

const SponsoredNetworkItem: React.FC<{
    item: SponsoredNetworkItem;
    openStates: Record<number, boolean>;
    handleOpen: (id: number) => void;
}> = ({ item, openStates, handleOpen }) => {
    return (
        <div className='py-[12px] px-[20px] shadow-lg rounded-md mb-4'>
            <h2 className='text-[1e293b] text-sm font-semibold'>{item.userid} - {item.rank_name}</h2>
            <h3 className='text-[1e293b] text-sm mb-4'>Date Join: {item.joining_date} Sponsored: {item.sponsored.length}</h3>
            <button type='button' className='px-[8px] py-[5px] rounded-md border text-xs text-[1e293b]' onClick={() => handleOpen(item.id)}>Check Sponsored Tree</button>
        </div>
    );
}

const SponsoredNetworkList: React.FC<{
    items: SponsoredNetworkItem[];
    openStates: Record<number, boolean>;
    handleOpen: (id: number) => void;
    subOpenStates: Record<number, boolean>;
    handleSub: (id: number) => void;
    subSubOpenStates: Record<number, boolean>;
    handleSubSub: (id: number) => void;
}> = ({ items, openStates, handleOpen, subOpenStates, handleSub, subSubOpenStates, handleSubSub }) => {
    return (
        <>
            {items.map(item => (
                <React.Fragment key={item.id}>
                    <SponsoredNetworkItem item={item} openStates={openStates} handleOpen={handleOpen} />
                    {openStates[item.id] && item.sponsored && (
                        <div className='ml-6'>
                            <SponsoredNetworkList
                                items={item.sponsored.filter(sponsor => sponsor.sponsorid === item.id)}
                                openStates={subOpenStates}
                                handleOpen={handleSub}
                                subOpenStates={subOpenStates}
                                handleSub={handleSub}
                                subSubOpenStates={subSubOpenStates}
                                handleSubSub={handleSubSub}
                            />
                        </div>
                    )}
                </React.Fragment>
            ))}
        </>
    );
}

function SposoredNetwork() {
    const dispatch = useDispatch<any>();
    const { sponsoredNetworkData } = useSelector((state: RootState) => state.sponsoorednetwork);

    useEffect(() => {
        dispatch(fetchSponsredNetwork({ userid: '' }));
    }, [dispatch]);

    const [openStates, setOpenStates] = useState<Record<number, boolean>>({}); // Track open state for each item
    const [subOpenStates, setSubOpenStates] = useState<Record<number, boolean>>({}); // Track sub-open state for each item
    const [subSubOpenStates, setSubSubOpenStates] = useState<Record<number, boolean>>({}); // Track subsub-open state for each item

    const handleOpen = (id: number) => {
        setOpenStates(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleSub = (id: number) => {
        setSubOpenStates(prev => ({ ...prev, [id]: !prev[id] }));
    };

    const handleSubSub = (id: number) => {
        setSubSubOpenStates(prev => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <>
            <Layout>
                <header className="fixed w-full h-14 bg-white flex items-center text-center shadow-md border-b border-custom-border">
                    <div className="container">
                        <div className="relative">
                            <Link to="/myteam" className="absolute left-0">
                                <svg
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m15 19-7-7 7-7"
                                    />
                                </svg>
                            </Link>
                            <h3 className="text-lg font-medium">Sponsored Network</h3>
                        </div>
                    </div>
                </header>
                <section className="pt-20 pb-20">
                    <div className="container">
                        <div className="p-[20px] bg-white">
                            {sponsoredNetworkData && Array.isArray(sponsoredNetworkData) ? (
                                <SponsoredNetworkList
                                    items={sponsoredNetworkData}
                                    openStates={openStates}
                                    handleOpen={handleOpen}
                                    subOpenStates={subOpenStates}
                                    handleSub={handleSub}
                                    subSubOpenStates={subSubOpenStates}
                                    handleSubSub={handleSubSub}
                                />
                            ) : "No Data Available"}
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default SposoredNetwork;
