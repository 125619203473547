import React from "react";

const DashboardBottom = () => {
    return (
        <>
            <section className="mt-5 mb-20">
                <div className="container">
                    <div className="bg-white shadow-custom-shadow p-2 sm:p-5 rounded-lg">
                        <div className="flex items-center justify-between text-center">
                            <h4
                                className="text-xs sm:text-sm font-medium text-warning-color border-b-2 border-warning-color pb-2 w-[48%]"
                            >
                                My Account Overview
                            </h4>
                            <h4 className="text-xs sm:text-sm font-medium text-custom-text-color pb-2 w-[48%]">
                                My Career
                            </h4>
                        </div>

                        <div className="mt-5 text-center">
                            <h5 className="text-xs sm:text-base font-medium text-custom-text-color">
                                Current Month 2023-11 - Ending 30-Nov-2023
                            </h5>

                            <div className="mt-5">
                                <table className="w-full  hidden">
                                    <tbody>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>

                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-[#EEEEF0]">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b border-custom-border">
                                        <td
                                            className="text-left text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Name
                                        </td>
                                        <td
                                            className="text-right text-xs text-custom-text-color2 font-normal p-2"
                                        >
                                            Kenneth Tham
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DashboardBottom;